import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { SpinnerService } from './services'
import { AppService } from './services/app'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _responsive: BreakpointObserver,
    private readonly _appService: AppService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _spinnerService: SpinnerService,
  ) {
    this._spinnerService.showSpinner$.subscribe(this._showSpinner.bind(this));
  }

  ngOnInit(): void {
    this._appService.autoAuth();
    this._responsive.observe(Breakpoints.XSmall).subscribe(res => {
      this._appService.setIsSmallDevice(res.matches);
    });
  }

  private _showSpinner(state: boolean): void {
    if (state) {
      this._spinner.show();
    } else {
      this._spinner.hide();
    }
  }
}
