import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthServiceModule } from '../auth';
import { LocalStorageServiceModule } from '../local-storage';
import { AppService } from './services/app.service';

@NgModule({
  imports: [
    CommonModule,
    AuthServiceModule.forRoot(),
    LocalStorageServiceModule.forRoot()
  ]
})
export class AppServiceModule {
  static forRoot(): ModuleWithProviders<AppServiceModule> {
    return {
      ngModule: AppServiceModule,
      providers: [AppService],
    };
  }
}
