export const environment: {
  production: boolean;
  url: string;
  urlApp: string;
  urlSimple: string;
  envName: string;
  clientId: string;
  publicId: string;
} = {
  production: false,
  url: 'https://dev.loveli.org.mx/apidev/public/api',
  urlApp: 'https://dev.loveli.org.mx/#/auth/restart-password/',
  urlSimple: 'https://dev.loveli.org.mx/#/',
  envName: 'DEV',
  clientId: 'AalvbwIehycK8CtceU8PHXEX4vFfc4hRC5FAJZraSvbJtbZpLnxKWIj4IaRr0r5QXNcaIvOeymuWcS1e', //paypal pruebas
  publicId: 'pk_test_51KZ0ILEAcxgqnwi3fBGZ3iAHFOnS3O5c0LqO7KQkJJFa9X06RurMIcDCyz3zNqFVLek3niO9pQ6PUpg4Orr4JCbu00bdzIBiaC'// stripe pruebas
  //publicId: 'pk_live_51KZ0ILEAcxgqnwi3FdYZlSSjgb5HJhxMfNGcUxzroKy4FtrY7CkRLLHpxr3nusbbvCTxZuxh9VdKUQdA4CRf598l00w35IxVsn' //stripe pruebas live

};
