import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ChargingSystemService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.url;
  }

  public uploadFiles(
    form: FormData
  ): Observable<{ estado: boolean; cargada_en: string }> {
    return this._http.post<{ estado: boolean; cargada_en: string }>(
      `${this._url}/sistema_de_carga`,
      form
    );
  }
}
