import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthData } from '@app/models/client'
import { LocalStorageService } from '@app/services'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
    private readonly _localStorageService: LocalStorageService
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const authData: AuthData = JSON.parse(
      this._localStorageService.getAuthData() as string
    ) as AuthData;

    if (authData.tw_role_id === 1 || authData.tw_role_id === 2) {
      return true;
    }

    this._router.navigateByUrl('dashboard/welcome');

    return false;
  }
}
