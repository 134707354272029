import { ModuleWithProviders, NgModule } from '@angular/core';
import { HelpersService } from './services/helpers.service';
import { MapsService } from './services/maps.service';

@NgModule({})
export class HelpersModule {
  static forRoot(): ModuleWithProviders<HelpersModule> {
    return {
      ngModule: HelpersModule,
      providers: [HelpersService, MapsService],
    };
  }
}
