import { registerLocaleData } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import localeEs from '@angular/common/locales/es'
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import * as moment from 'moment'
import { NgxSpinnerModule } from "ngx-spinner"
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthInterceptor, ErrorInterceptor, SpinnerInterceptor } from './interceptors'
import { AuthModule } from './pages/auth/auth.module'
import { AlertsServiceModule, AppServiceModule, LocalStorageServiceModule, SpinnerServiceModule } from './services'

registerLocaleData(localeEs, 'es');
moment.locale('es-mx');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    AppServiceModule.forRoot(),
    AlertsServiceModule.forRoot(),
    LocalStorageServiceModule.forRoot(),
    SpinnerServiceModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
