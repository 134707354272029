import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdminService } from './services/admin.service';

@NgModule({})
export class AdminServiceModule {
  static forRoot(): ModuleWithProviders<AdminServiceModule> {
    return {
      ngModule: AdminServiceModule,
      providers: [AdminService],
    };
  }
}
