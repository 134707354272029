import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AlertsService } from './services/alerts.service';

@NgModule({
  imports: [CommonModule]
})
export class AlertsServiceModule {
  static forRoot(): ModuleWithProviders<AlertsServiceModule> {
    return {
      ngModule: AlertsServiceModule,
      providers: [AlertsService],
    };
  }
}
