import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard, RoleGuard } from './guards'
import { AdminModule } from './pages/admin/admin.module'
import { DashboardModule } from './pages/dashboard/dashboard.module'
import { RouteModule } from './pages/route/route.module'
import { StaticModule } from './pages/static/static.module'
import { UpdateFamilyModule } from './pages/update-family/update-family.module'

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard/welcome'
      },
      {
        path: 'auth',
        loadChildren: (): Promise<any> => import('./pages/auth/auth.module').then(module => module.AuthModule)
      },
      {
        path: 'dashboard',
        loadChildren: (): Promise<any> =>
          import('./pages/dashboard/dashboard.module').then(
            (module): typeof DashboardModule => module.DashboardModule
          ),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin',
        loadChildren: (): Promise<any> =>
          import('./pages/admin/admin.module').then(
            (module): typeof AdminModule => module.AdminModule
          ),
        canActivate: [AuthGuard, RoleGuard]
      },
      {
        path: 'static',
        loadChildren: (): Promise<any> =>
          import('./pages/static/static.module').then(
            (module): typeof StaticModule => module.StaticModule
          )
      },
      {
        path: 'ruta',
        loadChildren: (): Promise<any> =>
          import('./pages/route/route.module').then(
            (module): typeof RouteModule => module.RouteModule
          )
      },
      {
        path: 'actualizate',
        loadChildren: (): Promise<any> =>
          import('./pages/update-family/update-family.module').then(
            (module): typeof UpdateFamilyModule => module.UpdateFamilyModule
          )
      },
      {
        path: 'completarUsuario',
        loadChildren: () =>
          import('./pages/complete-user/complete-user.module').then(module => module.CompleteUserModule)
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],

})
export class AppRoutingModule {}
