import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SpinnerService } from '@app/services'
import { finalize, Observable } from 'rxjs'

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  private _excludedUrls: string[];

  constructor(private readonly _spinnerService: SpinnerService) {
    this._excludedUrls = ['notificacionesSinLeer'];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this._excludedUrls.some((url: string): boolean => request.url.includes(url))) return next.handle(request);

    this._spinnerService.handleRequest('plus')

    return next.handle(request).pipe(finalize(this._finalizeRequest.bind(this)));
  }

  private _finalizeRequest(): void {
    this._spinnerService.handleRequest();
  }
}
