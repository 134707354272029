import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Campaign, Rol, User, UsersResponse } from '@app/models/server'
import { environment } from '@src/environments/environment'
import { BehaviorSubject, map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private _campaignsSubj: BehaviorSubject<Campaign[]>;
  private _url: string;
  private _campaigns: Campaign[];

  public campaigns$;

  constructor(private readonly _http: HttpClient) {
    this._campaignsSubj = new BehaviorSubject<Campaign[]>([]);
    this._url = environment.url;
    this._campaigns = [];

    this.campaigns$ = this._campaignsSubj.asObservable();
  }

  public setCampaigns(campaigns: Campaign[]): void {
    this._campaignsSubj.next(campaigns);
  }

  public set campaigns(campaigns: Campaign[]) {
    this._campaigns = campaigns;
  }

  public get campaigns(): Campaign[] {
    return this._campaigns;
  }

  public getUsers(): Observable<User[]> {
    return this._http.get<UsersResponse>(`${this._url}/usuarios`).pipe(
      map((res: UsersResponse): User[] =>
        res.data
          .map((user) => {
            return {
              ...user,
              created_at: new Date(String(user.created_at).replace(/\s/, 'T')),
              updated_at: new Date(String(user.updated_at).replace(/\s/, 'T')),
            };
          })
          .filter(
            (user: User): boolean => {
              const { id } = user.tw_role_id as Rol;

              return id === 2 || id === 3;
            }
          )
      )
    );
  }

  public getAllUsers(currentUserId: number): Observable<User[]> {
    return this._http.get<UsersResponse>(`${this._url}/usuarios`).pipe(
      map((res: UsersResponse): User[] =>
        res.data
          .map((user: User): User => {
            return {
              ...user,
              created_at: new Date(String(user.created_at).replace(/\s/, 'T')),
              updated_at: new Date(String(user.updated_at).replace(/\s/, 'T')),
            };
          })
          .filter(
            (user: User): boolean => user.id !== currentUserId
          )
      )
    );
  }

  public getDeliveries(): Observable<User[]> {
    return this._http.get<UsersResponse>(`${this._url}/usuarios`).pipe(
      map((res: UsersResponse): User[] =>
        res.data
          .map((user) => {
            return {
              ...user,
              N_TelWhatsapp: `+${user.N_TelWhatsapp}`,
              created_at: new Date(String(user.created_at).replace(/\s/, 'T')),
              updated_at: new Date(String(user.updated_at).replace(/\s/, 'T')),
            };
          })
          .filter((user: User): boolean => user.esRepartidor === 1)
      )
    );
  }

  public deleteDelivery(id: number): Observable<{ data: string }> {
    return this._http.delete<{ data: string }>(`${this._url}/usuarios/${id}`);
  }

  public getCampaigns(): Observable<{ data: { data: Campaign[] } }> {
    return this._http.get<{ data: { data: Campaign[] } }>(
      `${this._url}/campanias`
    );
  }
}
