import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoginResponse, LoginResponseAuth, RequestCode, RequestCodeResponse, SignUpResponse, VerifyCode } from '@app/models/server'
import { UserRequest } from '@app/models/server/auth/user-request'
import { environment } from '@src/environments/environment'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _url: string;
  private _openRecoveryPasswordDialog$: Subject<boolean> = new Subject();

  constructor(private readonly _http: HttpClient) {
    this._url = environment.url;
  }

  get isOpenRecoveryPasswordDialog$(): Observable<boolean> {
    return this._openRecoveryPasswordDialog$.asObservable();
  }

  openRecoveryPasswordDialog(): void {
    this._openRecoveryPasswordDialog$.next(true);
  }

  public login(
    emailOrPhone: string,
    password: string,
    isPhone?: boolean
  ): Observable<LoginResponse> {
    let body;

    if (isPhone) {
      body = {
        telwhatsapp: emailOrPhone,
      };
    } else {
      body = {
        email: emailOrPhone,
      };
    }

    return this._http.post<LoginResponse>(`${this._url}/login`, {
      ...body,
      password,
    });
  }

  public recoveryPassword(
    email: string,
    url: string
  ): Observable<{ data: string }> {
    return this._http.post<{ data: string }>(`${this._url}/recuperarPassword`, {
      email,
      url,
    });
  }

  public restartPassword(
    email: string,
    password: string,
    confirmPassword: string,
    token: string
  ): Observable<{ data: string }> {
    return this._http.post<{ data: string }>(
      `${this._url}/actualizarPassword`,
      {
        email,
        password,
        password_confirmation: confirmPassword,
        token,
      }
    );
  }

  public logout(): Observable<{ success: string }> {
    return this._http.get<{ success: string }>(`${this._url}/logout`);
  }

  public signUp(body: UserRequest): Observable<SignUpResponse> {
    return this._http.post<SignUpResponse>(
      `${this._url}/registrarUsuario`,
      body
    );
  }

  public requestCode(body: RequestCode): Observable<RequestCodeResponse> {
    return this._http.post<RequestCodeResponse>(`${this._url}/solicitarCodigo`, body);
  }

  public verifyCode(body: VerifyCode): Observable<LoginResponseAuth> {
    return this._http.post<LoginResponseAuth>(`${this._url}/verificarCodigo`, body);
  }
}