import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { Observable, from, map, of, switchMap, tap } from 'rxjs';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  private geoCoder: any;
  constructor( private mapLoader: MapsAPILoader) { }

  private initGeocoder() {
    this.geoCoder = new google.maps.Geocoder;
  }

  geocodeAddress(geometry:{lat:number, lng:number}): Observable<any> {
    return this.waitForMapsToLoad().pipe(
      switchMap(() => {
        return new Observable(observer => {
          this.geoCoder.geocode({'location':geometry}, (results:any, status:any) => {
            if (status == google.maps.GeocoderStatus.OK) {
              observer.next({
                result:results[0]
              });
            } else {
                observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        })        
      })
    )
  }

  private waitForMapsToLoad(): Observable<boolean> {
    if(!this.geoCoder) {
      return from(this.mapLoader.load())
      .pipe(
        tap(() => this.initGeocoder()),
        map(() => true)
      );
    }
    return of(true);
  }
}
