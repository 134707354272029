import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { DeliveryUpdateRequest, DeliveryUpdateResponse } from '@app/models/server/deliveries'
import { AuthService } from '@app/services/auth'
import { LocalStorageService } from '@app/services/local-storage'
import { environment } from '@src/environments/environment'
import { Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public notificationsCounter$: Observable<number>;
  public isSmallDevice$: Observable<boolean>;
  public isSmallDevice: boolean;

  private _tokenTimer!: any;
  private _notifications: Subject<number>;
  private _isSmallDevice: Subject<boolean>;
  private _url: string;

  constructor(
    private readonly _http: HttpClient,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _localStorageService: LocalStorageService
  ) {
    this.isSmallDevice = true;
    this._isSmallDevice = new Subject();
    this._notifications = new Subject();
    this.notificationsCounter$ = this._notifications.asObservable();
    this.isSmallDevice$ = this._isSmallDevice.asObservable();

    this._url = environment.url;
  }

  public setIsSmallDevice(value: boolean): void {
    this.isSmallDevice = value;

    this._isSmallDevice.next(value);
  }

  public setNotificationsCounter(count: number): void {
    this._notifications.next(count);
  }

  public autoAuth(): void {
    const token: string | null = this._localStorageService.getToken();

    if (token) {
      const tokenDecode: any = JSON.parse(atob(token.split('.')[1]));
      const expires: Date = new Date(tokenDecode.exp * 1000);
      const timeoutTemp: number = expires.getTime() - Date.now() - (60 * 1000);
      const timeoutDay: number = (timeoutTemp - 86400000);
      const timeout: number = timeoutTemp - timeoutDay;

      if (timeoutTemp > 0) {
        this._tokenTimer = setTimeout((): void => {
          this.logout()
        }, timeout);
      }
    }
  }

  public logout(): void {
    this._authService.logout().subscribe({
      next: (): void => {
        clearTimeout(this._tokenTimer);
        this._localStorageService.removeToken();
        this._router.navigateByUrl('auth/login');
      },
      error: (): void => {
        clearTimeout(this._tokenTimer);
        this._localStorageService.removeToken();
        this._router.navigateByUrl('auth/login');
      }
    })
  }

  public registerDelivery(
    delivery: DeliveryUpdateRequest
  ): Observable<DeliveryUpdateResponse> {
    return this._http.post<DeliveryUpdateResponse>(
      `${this._url}/usuarios`,
      delivery
    );
  }

  public updateDelivery(delivery: DeliveryUpdateRequest, id: number) {
    return this._http.put(`${this._url}/usuarios/${id}`, delivery);
  }
}
