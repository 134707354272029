import { Injectable } from '@angular/core'

const TOKEN: string = 'loveli-token';
const DATA: string = 'loveli-data';
const PROPERTY: string = 'loveli-property';
const CURRENT_CAMPAIGN: string = 'current-campaign';
const CURRENT_FAMILY: string = 'current-family';
const SELECTED_USERS: string = 'selected-users';
const SELECTED_RECIPROCAL_USERS: string = 'selected-reciprocal-users';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public setToken(token: string | undefined): void {
    localStorage.setItem(TOKEN, token as string);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  public setAuthData(authData: string | undefined): void {
    localStorage.setItem(DATA, authData as string)
  }

  public getAuthData(): string | null {
    return localStorage.getItem(DATA);
  }

  public setProperty(authData: string | undefined): void {
    localStorage.setItem(PROPERTY, authData as string)
  }

  public getProperty(): string | null {
    return localStorage.getItem(PROPERTY);
  }

  public setCurrentCampaign(currentCampaign: string | undefined): void {
    localStorage.setItem(CURRENT_CAMPAIGN, currentCampaign as string);
  }

  public getCurrentCampaign(): string | null {
    return localStorage.getItem(CURRENT_CAMPAIGN);
  }

  public setCurrentFamily(currentFamily: string | undefined): void {
    localStorage.setItem(CURRENT_FAMILY, currentFamily as string);
  }

  public getCurrentFamily(): string | null {
    return localStorage.getItem(CURRENT_FAMILY);
  }

  public setSelectedUsers(selectedCommunity: string): void {
    localStorage.setItem(SELECTED_USERS, selectedCommunity as string);
  }

  public getSelectedUsers(): string | null {
    return localStorage.getItem(SELECTED_USERS);
  }

  public setSelectedReciprocalUsers(selectedCommunity: string): void {
    localStorage.setItem(SELECTED_RECIPROCAL_USERS, selectedCommunity as string);
  }

  public getSelectedReciprocalUsers(): string | null {
    return localStorage.getItem(SELECTED_RECIPROCAL_USERS);
  }

  public removeToken(): void {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(DATA);
    localStorage.removeItem(CURRENT_CAMPAIGN);
    localStorage.removeItem(CURRENT_FAMILY);
    localStorage.removeItem(PROPERTY);
  }

  public removeSelectedUsers(): void {
    localStorage.removeItem(SELECTED_USERS);
  }

  public removeSelectedReciprocalUsers(): void {
    localStorage.removeItem(SELECTED_RECIPROCAL_USERS);
  }
}
