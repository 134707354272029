import { Injectable } from '@angular/core'
import Swal, { SweetAlertResult } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  public showMessageSuccess(message: string, title?: string): void {
    Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'success',
      title: title ? title : 'Success',
      text: message,
      iconColor: '#0D4B79',
      confirmButtonText: 'Cerrar'
    });
  }

  public showMessageError(message: string, title?: string): void {
    Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'error',
      title: title ? title : 'Error',
      text: message,
      iconColor: '#E67E22',
      confirmButtonText: 'Cerrar'
    });
  }

  public showConfirmMessage(title: string, message: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'warning',
      title: title,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      showCancelButton: true,
      cancelButtonColor: '#6e7881',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      focusCancel: true
    });
  }

  public showConfirmOption(title: string, message: string, option1: string, option2: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'warning',
      title: title,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      showCancelButton: true,
      cancelButtonColor: '#6e7881',
      cancelButtonText: option2,
      confirmButtonText: option1,
      focusConfirm: true
    });
  }

  public showConfirmLabelTextareaOption(input: 'text' | 'textarea', title: string, message: string, inputLabel: string, option1: string, option2: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'warning',
      title: title,
      input,
      inputLabel,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      showCancelButton: true,
      cancelButtonColor: '#6e7881',
      cancelButtonText: option2,
      confirmButtonText: option1,
      focusConfirm: true
    });
  }

  public showConfirmOptionNoClose(title: string, message: string, option: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'warning',
      title: title,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonText: option,
      focusConfirm: true
    });
  }
  public aviso(title: string, message: string, footer?: string): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      confirmButtonColor: '#7CB342',
      icon: 'success',
      title: title,
      text: message,
      footer: footer ? footer : undefined,
      iconColor: '#0D4B79',
      allowOutsideClick: false,
      showCancelButton: false,
      focusConfirm: true
    });
  }
}
