import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginModule } from './login/login.module';
import { RestartPasswordModule } from './restart-password/restart-password.module';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: (): Promise<any> =>
      import('./login/login.module').then((module): LoginModule => module.LoginModule),
  },
  {
    path: 'restart-password/:token',
    loadChildren: (): Promise<any> =>
      import('./restart-password/restart-password.module').then(
        (module): RestartPasswordModule => module.RestartPasswordModule
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
