import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  AddressRequest,
  AddShoppingCartResponse,
  ColonyResponse,
  CommunityResponse,
  Direccion,
  FamilyAddressRequest,
  FamilyResponse,
  GetWishListResponse,
  GiftsRequest,
  GiftsResponse,
  ShoppingCartResponse,
  User,
  UserDataUpdate,
  UserUpdateRequest,
  WishListRequest,
  WishListResponse
} from '@app/models/server'
import { UnreadNotificationsResponse } from '@app/models/server/notifications'
import { environment } from '@src/environments/environment'
import { catchError, map, Observable, of } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  private _url: string;

  constructor(private readonly _http: HttpClient) {
    this._url = environment.url;
  }

  public getCommunities(): Observable<{ data: CommunityResponse[] }> {
    return this._http.get<{ data: CommunityResponse[] }>(
      `${this._url}/comunidades`
    );
  }

  public getColonies(zipCode: string): Observable<ColonyResponse[]> {
    return this._http.get<ColonyResponse[]>(
      `${this._url}/obtenerCP/${zipCode}`
    );
  }

  public verifyUser(
    email: string,
    telwhatsapp: string
  ): Observable<{ message: string; data: User | null }> {
    const body: any = telwhatsapp ? { telwhatsapp } : email && { email };

    return this._http.post<{ message: string; data: User | null }>(
      `${this._url}/verificarUsuario`,
      body
    );
  }

  public verifyEmail(email: string): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(
      `${this._url}/verificarUsuario`,
      { email }
    );
  }

  public verifyPhoneNumber(phone: string): Observable<{ message: string }> {
    return this._http.post<{ message: string }>(
      `${this._url}/verificarUsuario`,
      { telwhatsapp: phone }
    );
  }

  public updateUser(
    user: UserUpdateRequest,
    id: number
  ): Observable<{ success: boolean; errors: null; data: User }> {
    return this._http.put<{ success: boolean; errors: null; data: User }>(
      `${this._url}/usuarios/${id}`,
      user
    );
  }

  public updateAddress(
    id: number,
    address: AddressRequest
  ): Observable<{ data: Direccion }> {
    return this._http.put<{ data: Direccion }>(
      `${this._url}/direcciones/${id}`,
      address
    );
  }

  public updateFamilyAddress(body: FamilyAddressRequest) {
    return this._http.put(`${this._url}/actualizarFamiliaMin`, body);
  }

  public unreadNotifications(): Observable<UnreadNotificationsResponse> {
    return this._http.get<UnreadNotificationsResponse>(
      `${this._url}/notificacionesSinLeer`
    );
  }

  public readNotifications(): Observable<UnreadNotificationsResponse> {
    return this._http.get<UnreadNotificationsResponse>(
      `${this._url}/notificacionesLeidas`
    );
  }

  public getShoppingCart(id: number): Observable<ShoppingCartResponse> {
    return this._http.post<ShoppingCartResponse>(
      `${this._url}/carrito-usuario?id=${id}`,
      {}
    );
  }

  public getWishList(id: number): Observable<GetWishListResponse> {
    return this._http.get<GetWishListResponse>(
      `${this._url}/wishlist/verWishList/${id}`
    );
  }

  public removeItemShoppingCart(
    id_carrito: string,
    id_usuario: string
  ): Observable<any> {
    return this._http.post<any>(`${this._url}/carrito-eliminar`, {
      id_carrito,
      id_usuario,
    });
  }

  public markNotification(
    notificationId: string
  ): Observable<{ success: boolean }> {
    return this._http.post<{ success: boolean }>(
      `${this._url}/marcarNotificacionLeida`,
      { notificacion_id: notificationId }
    );
  }

  public getFamiliesWithAddresses(): Observable<any> {
    return this._http.get<any>(`${this._url}/familiasConDirecciones`);
  }

  public getLoadMap(): Observable<any> {
    return this._http
      .jsonp(
        'https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE',
        'callback'
      )
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public getFamily(id: number): Observable<FamilyResponse> {
    return this._http.get<FamilyResponse>(`${this._url}/familias/${id}`);
  }

  public removeUserFromWishList(body: {
    usuarioDueno: number;
    usuarioParaQuitar: number;
  }): Observable<WishListResponse> {
    return this._http.post<WishListResponse>(
      `${this._url}/wishlist/eliminarPersonas`,
      body
    );
  }

  public moveUsers(body: WishListRequest): Observable<WishListResponse> {
    return this._http.post<WishListResponse>(
      `${this._url}/wishlist/moverPersonas`,
      body
    );
  }

  public getGifts(body: GiftsRequest): Observable<GiftsResponse> {
    const { usuario_id, campania_id } = body;
    return this._http.get<GiftsResponse>(`${this._url}/regalosUsuario`, {
      params: {
        usuario_id,
        campania_id,
      },
    });
  }

  public addItemShoppingCart(id_duenio: string, id_usuario: string): Observable<AddShoppingCartResponse> {
    return this._http.post<AddShoppingCartResponse>(
      `${this._url}/carrito-agregar`,
      { id_duenio, id_usuario }
    );
  }

  public getUser(id: number): Observable<{ data: User }> {
    return this._http.get<{ data: User }>(`${this._url}/usuarios/${id}`);
  }

  public updateUserAndFamily(id: number, data: UserDataUpdate){
    return this._http.put(`${this._url}/extActualizarUsuario/${id}`, data)
  }


}
